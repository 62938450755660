<template>
  <b-row style="max-width: 100rem; margin: auto;">
    <b-col
          cols="12"
          xl="8"
          lg="8"
          md="8"
        >
        <user-view-user-info-card v-if="userData" :userData="userData"></user-view-user-info-card>
    </b-col>
     <b-col
          cols="12"
          md="4"
          xl="4"
          lg="4"
        >
        <user-view-user-action v-if="userData"  :uid="userData._id"></user-view-user-action>
    </b-col>
    <b-col
          cols="12"
          md="12"
          xl="12"
          lg="12"
        >
        <plan v-if="userData" :uid="userData._id"></plan>
    </b-col>
   

    <b-col
          cols="12"
          md="12"
          xl="12"
          lg="12"
        >
        <billing-history v-if="billings && userData" :uid="userData._id" :items="billings"></billing-history>
    </b-col>

    <b-col
          cols="12"
          md="12"
          xl="12"
          lg="12"
        >
        <user-add-fund-history v-if="payments && userData" :uid="userData._id" :items="payments"></user-add-fund-history>
    </b-col>

  </b-row>
  
</template>

<script>
import {
  BCard, BButton, BAvatar, BRow, BCol, BDropdownItem, BDropdown,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import UserViewUserInfoCard from './UserViewUserInfoCard'
import UserViewUserAction from './UserViewUserAction.vue'
import UserAddFundHistory from './AddFundHistory1'
import BillingHistory from './BillingHistory.vue'
import UserActivity from './Activity'
import Supports from './Support.vue'
import Subscription from './Subscription.vue'
import UserViewPlan from './UserViewViewPlan.vue'
import UserViewPort from './UserViewViewPort.vue'
import PORTS from './Ports.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import RepositoryFactory from '../../../api/RepositoryFactory'
const UserManagersRepository = RepositoryFactory.get('usermanagers')
const ReportManagerReposity = RepositoryFactory.get('adminReport')
import data from './ultis'

export default {
  components: {
    BCard, BButton, BRow, BCol, BAvatar,BDropdownItem, BDropdown,
    UserViewUserInfoCard,UserViewUserAction,'user-add-fund-history':UserAddFundHistory,
    'user-activity':UserActivity, 'support': Supports, 'subscription':Subscription, 'billing-history':BillingHistory, 'ports': UserViewPort,
    'plan': UserViewPlan,

  },
  data(){
    return {
      userData: null,
      payments:null,
      data: null,
      subscriptions: null,
      billings: null,
      ports: null
    }
  },
  created(){
    UserManagersRepository.fetchById(this.$route.params.id).then(rs=>{
      this.userData = rs.data.data
      this.userData.plans = rs.data.plans
      this.userData.keys = rs.data.keys
    }).catch(e=>{

    })
    UserManagersRepository.fetchPayment(this.$route.params.id).then(rs=>{
      this.payments = rs.data.data

    }).catch(e=>{

    })


    // UserManagersRepository.fetchPorts(this.$route.params.id).then(rs=>{
    //   this.ports = rs.data.data
    // })

    UserManagersRepository.fetchBilling(this.$route.params.id).then(rs=>{
      this.billings = rs.data.data
    })

    // ReportManagerReposity.fetchById(this.$route.params.id).then(rs=>{
    //   this.data = rs.data.data
    // }).catch(e=>{

    // })


  },
  methods: {
    updateUserBalance(id,balance){
      if(balance > 0)
        this.userData.total += parseFloat(balance)
      this.userData.balance += parseFloat(balance)
    },
    updateUserBW(id,bw){
      if(bw > 0){
        this.userData.bandwidth.total += parseFloat(bw)
      }
      
    }
  }

}
</script>


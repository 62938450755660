<template>
  <b-card-code
    title="History"
    no-body
  >
    <b-card-body>
      <div class="d-flex justify-content-between  flex-wrap">

        <!-- sorting  -->
        <b-form-group
          label="Sort"
          label-size="sm"
          label-align-sm="left"
          label-cols-sm="2"
          label-for="sortBySelect"
          class="mr-1 mb-md-0"
        >
          <b-input-group
            size="sm"
          >
            <b-form-select
              id="sortBySelect"
              v-model="sortBy"
              :options="sortOptions"
            >
              <template #first>
                <option value="">
                  none
                </option>
              </template>
            </b-form-select>
            <b-form-select
              v-model="sortDesc"
              size="sm"
              :disabled="!sortBy"
            >
              <option :value="false">
                Asc
              </option>
              <option :value="true">
                Desc
              </option>
            </b-form-select>
          </b-input-group>
        </b-form-group>

        <!-- filter -->
        <b-form-group
          label="Filter"
          label-cols-sm="2"
          label-align-sm="left"
          label-size="sm"
          label-for="filterInput"
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-input
              id="filterInput"
              v-model="filter"
              type="search"
              placeholder="Type to Search"
            />
            <b-input-group-append>
              <b-button
                :disabled="!filter"
                @click="filter = ''"
              >
                Clear
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </div>
    </b-card-body>

    <b-table
      striped
      hover
      responsive
      class="position-relative"
      :per-page="perPage"
      :current-page="currentPage"
      :items="items"
      :fields="fields"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      :filter="filter"
      :filter-included-fields="filterOn"
      @filtered="onFiltered"
    >
      <template #cell(_id)="data">
        #{{data.item._id }}
      </template>
      <template #cell(bank)="data">
        {{banksConfig[data.item.method] ? banksConfig[data.item.method].name : "" }}
      </template>
      <template #cell(amount)="data">
        {{Number(data.item.amount).toLocaleString('en-US', { style: 'currency', currency: 'VND' })}}
      </template>
      <template #cell(dollar)="data">
        {{Number(data.item.dollar).toLocaleString('en-US', { style: 'currency', currency: 'USD' })}}
      </template>
      <template #cell(exchange)="data">
        1$ = {{ Number(data.item.exchange).toLocaleString('en-US', { style: 'currency', currency: 'VND' })}}
      </template>
      <template #cell(created_at)="data">
        {{data.item.created_at.toLocaleString('en-US')}}
      </template>
      <template #cell(status)="data">
        <b-badge :variant="status[1][data.value]">
          {{ status[0][data.value] }}
        </b-badge>
      </template>
      <template #cell(created_at)="data">
        {{formatDateTime(data.item.created_at)}}
      </template>
    </b-table>

    <b-card-body class="d-flex justify-content-between flex-wrap pt-0">

      <!-- page length -->
      <b-form-group
        label="Per Page"
        label-cols="6"
        label-align="left"
        label-size="sm"
        label-for="sortBySelect"
        class="text-nowrap mb-md-0 mr-1"
      >
        <b-form-select
          id="perPageSelect"
          v-model="perPage"
          size="sm"
          inline
          :options="pageOptions"
        />
      </b-form-group>

      <!-- pagination -->
      <div>
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
          class="mb-0"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </div>
    </b-card-body>

  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import {
  BTable, BAvatar, BBadge, BFormGroup, BFormSelect, BPagination, BInputGroup, BFormInput, BInputGroupAppend, BButton, BCardBody,
} from 'bootstrap-vue'

export default {
  props: {
    items: {
      type: Array,
      default: () => [],
    }
  },
  components: {
    BCardCode,
    BTable,
    BAvatar,
    BBadge,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCardBody,
  },
  data() {
    return {
      perPage: 5,
      pageOptions: [5, 10, 20,50,100],
      totalRows: 100,
      currentPage: 1,
      sortBy: 'created_at',
      sortDesc: true,
      sortDirection: 'desc',
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        {
          key: '_id', label: 'Id',
        },
        { key: 'bankname', label: 'Bank Name', sortable: true },
        { key: 'amount', label: 'Amount', sortable: true },
        { key: 'dollar', label: 'Dollar', sortable: true },
        { key: 'exchange', label: 'Exchange Rate', sortable: true },
        { key: 'created_at', label: 'Date Created', sortable: true },
        { key: 'status', label: 'Status', sortable: true },
      ],
      status: [{
        1: 'Success', 2: 'Pending', 3: 'Rejected', 4: 'Refund',
      },
      {
        1: 'light-success', 2: 'light-warning', 3: 'light-danger', 4: 'light-info',
      }],
      banksConfig:{
          0:{
            name: "Vietcombank",
            account:'Nguyen Trung Long',
            number: '0161001597083',
            address: 'Chi Nhanh Hue'
          },
          1:{
            name: "Dong A",
            account:'Nguyen Trung Long',
            number: '0161001597083',
            address: 'Chi Nhanh Hue'
          },
          2:{
            name: "Paypal",
            account:'Nguyen Trung Long',
            number: 'rani@qcodes.net',
            address: 'Chi Nhanh Hue'
          },
          3:{
            name: "Admin",
            account:'Nguyen Trung Long',
            number: 'rani@qcodes.net',
            address: 'Chi Nhanh Hue'
          }
      },
    }
  },
  created(){
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  mounted() {
    // Set the initial number of items
    
    this.totalRows = this.items.length
  },
  methods: {
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    mountData(){
      this.totalRows = this.items.length
    },
    formatDateTime(d){
      let date = new Date(d)
      let options = { year: 'numeric', month: 'numeric', day: 'numeric',
        hour: 'numeric', minute: 'numeric', second: 'numeric',
        hour12: false}
        return new Intl.DateTimeFormat('vi-VN', options).format(date)
    },
  },
}
</script>

<template>
  <b-card
    no-body
    class="border-primary"
  >
    <b-card-header class="d-flex justify-content-between align-items-center pt-75 pb-25">
      <h5 class="mb-1">
        Current Plan
      </h5>
    </b-card-header>
    <b-card-body>
      <b-form-group
        label="Add Funds"
        label-for="basicInput"
      >
        <b-input-group>
          <b-input-group-prepend>
            <b-button variant="outline-primary"
            @click="addf(0)">
              <feather-icon icon="MinusIcon" />
            </b-button>
          </b-input-group-prepend>
          <b-form-input placeholder="Amount" type="number" v-model="amount"  />
          <b-input-group-append>
            <b-button variant="outline-primary"
            @click="addf(1)">
              <feather-icon icon="PlusIcon" />
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
      <!-- <b-form-group
        label="Add BW"
        label-for="basicInput"
      >
        <b-input-group>
          <b-input-group-prepend>
            <b-input-group-text><strong class="text-primary">{{ formatBytes(bw) }}</strong></b-input-group-text>
            
          </b-input-group-prepend>
          <b-form-input placeholder="Bandwidth" type="number" v-model="bw"  />
          <b-input-group-append>
            <b-button variant="outline-primary"
            @click="addBW()">
              <feather-icon icon="PlusIcon" />
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-form-group> -->
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BBadge, BButton, BFormGroup, BFormInput, BInputGroupPrepend,BInputGroupAppend, BInputGroup, BInputGroupText
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import RepositoryFactory from '../../../api/RepositoryFactory'
const UserManagersRepository = RepositoryFactory.get('usermanagers')
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  props:{
    uid: {
      type: String,
      default: ()=>""
    }
  },
  directives: {
    Ripple,
  },
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BBadge,
    BButton,
    BFormGroup,
    BFormInput,
    BInputGroupPrepend,
    BInputGroupAppend,
    BInputGroup,
    BInputGroupText
  },
  data(){
    return {
      amount: 0,
      bw: 0
    }
  },
  mounted(){
  },
  methods:{
    addf(n){
      let real_amout = this.amount
      if(n != 1)
        real_amout = this.amount*-1
      UserManagersRepository.addfund(this.uid,real_amout).then(rs=>{
        if(rs.data.success){
          this.$parent.updateUserBalance(this.uid,real_amout)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Success',
              icon: 'CheckIcon',
              variant: 'success'
            },
          });
        }else{
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error: '+rs.data.errMsg,
              icon: 'AlertOctagonIcon',
              variant: 'danger'
            },
          });
        }
      }).catch(e=>{
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error: '+e.message,
            icon: 'AlertOctagonIcon',
            variant: 'danger'
          },
        });
      })
    },
    addBW(){
      UserManagersRepository.addMoreBW(this.uid,{bw: this.bw}).then(rs=>{
        if(rs.data.success){
          this.$parent.updateUserBW(this.uid,this.bw)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Success',
              icon: 'CheckIcon',
              variant: 'success'
            },
          });
        }else{
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Error: '+rs.data.errMsg,
              icon: 'AlertOctagonIcon',
              variant: 'danger'
            },
          });
        }
      }).catch(e=>{
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error: '+e.message,
            icon: 'AlertOctagonIcon',
            variant: 'danger'
          },
        });
      })
    },
    formatBytes(bytes) {
        if(bytes == undefined)
          bytes = 0
        let spector = ""
        if(bytes < 0){
            spector = "-"
            bytes = bytes * -1
        }
            var marker = 1000; // Change to 1000 if required
            var decimal = 2; // Change as required
            var kiloBytes = marker; // One Kilobyte is 1024 bytes
            var megaBytes = marker * marker; // One MB is 1024 KB
            var gigaBytes = marker * marker * marker; // One GB is 1024 MB
            var teraBytes = marker * marker * marker * marker; // One TB is 1024 GB
            // return bytes if less than a KB
            if(bytes < kiloBytes) return spector+bytes + " Bytes";
            // return KB if less than a MB
            else if(bytes < megaBytes) return spector+(bytes / kiloBytes).toFixed(decimal) + " KB";
            // return MB if less than a GB  ``
            else if(bytes < gigaBytes) return spector+(bytes / megaBytes).toFixed(decimal) + " MB";
            // return GB if less than a TB
            else if(bytes < teraBytes) return spector+(bytes / gigaBytes).toFixed(decimal) + " GB";
            
            else return spector+(bytes / teraBytes).toFixed(decimal) + " TB";
        },
  }
}
</script>

<style>

</style>
